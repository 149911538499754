import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const ModelRenderer = ({ modelUrl }) => {
    const [playing, setPlaying] = useState(false);

    const handleClick = () => {
        setPlaying(true);
    }

    return (
        <div className={`relative aspect-w-16 aspect-h-9 w-full  `}>
            <Helmet>
                <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
                <script nomodule src="https://unpkg.com/@google/model-viewer/dist/model-viewer-legacy.js"></script>
            </Helmet>
            {/* <div 
                className={`absolute z-10 top-0 left-0 w-full h-full ${playing ? 'opacity-0' : 'opacity-100'} transition-opacity duration-500 bg-black flex items-center justify-center cursor-pointer`}
                onClick={handleClick}
            >
                <button className="p-2 text-white bg-red-600">Play</button>
            </div> */}
            <model-viewer
                className="absolute z-0 top-0 left-0 aspect-w-16 aspect-h-9"
                src={modelUrl}
                alt="A 3D model of an object"
                auto-rotate
                camera-controls
            >
            </model-viewer>
        </div>
    );
};

export default ModelRenderer;
