import * as React from "react"
// import Layout from "../components/layout"
import Seo from "../components/seo"
import { FiAlertTriangle } from "react-icons/fi"
import ModelRenderer from "../components/ModelRenderer"
import {GiVibratingBall} from 'react-icons/gi'

const SVPage = () => {
  return (
    <div>
      <Seo title="SV - Project 342-EM-05-2023" />
      <div
        className="bg-indigo-100 text-[0.5rem] tracking-widest flex items-center gap-2 justify-center py-5 px-6 mb-4 text-xs text-indigo-700"
        role="alert"
      >
        <FiAlertTriangle />
        CONFIDENTIAL - PASSWORD PROTECTED            <FiAlertTriangle />
  </div>

      <div className="flex flex-col justify-center pt-4">

        <div className="w-11/12 mx-auto mb-8 text-sm">
        <h1 className="text-xl lg:text-2xl my-0 font-bold tracking-widest uppercase mt-2 mb-2 ">
       Project 342-EM-05-2023
        </h1>
        <p >Below, you will find the initial results from our first 3D scanning attempt of the Scarab Vase. This visual presentation depicts the rich, intricate details we were able to capture, alongside the areas where the surface geometry data was not fully rendered due to light and scanning path variations.</p>

        </div>
        {/* Main */}
        <section className="bg-zinc-800 p-2 justify-center items-center mx-auto w-11/12 mb-8">
          <div className='text-xs tracking-widest text-white flex justify-between'>
              <strong>SCARAB VASE MAIN</strong>
              <div className='text-[0.5rem] tracking-widest text-white uppercase'>Budmen Digitization Division</div>
          </div>
            <ModelRenderer  modelUrl="https://figment-objects.s3.us-west-004.backblazeb2.com/Scarab-Vase-Main.glb"/>
            <div className='text-[0.5rem] flex justify-between tracking-widest text-white uppercase'>
              <div>Points:5,545,421 | Faces: 11,088,502 </div>
              <div className="text-sm flex items-center"><span className='text-[0.5rem] mr-2'>Scan 5.17.23</span><GiVibratingBall/> </div>
          </div>
        </section>
        {/* Main */}
        <section className="bg-zinc-800 p-2 justify-center items-center mx-auto w-11/12 mb-8">
          <div className='text-xs tracking-widest text-white flex justify-between'>
              <strong>SCARAB VASE TOP</strong>
              <div className='text-[0.5rem] tracking-widest text-white uppercase'>Budmen Digitization Division</div>
          </div>
            <ModelRenderer  modelUrl="https://figment-objects.s3.us-west-004.backblazeb2.com/Scarab-Vase-Top-R1.glb"/>
            <div className='text-[0.5rem] flex justify-between tracking-widest text-white uppercase'>
            <div>Points:1,013,779 | Faces: 2,025,983 </div>
              <div className="text-sm flex items-center"><span className='text-[0.5rem] mr-2'>Scan 5.17.23</span><GiVibratingBall/> </div>
          </div>
        </section>
                {/* Main */}
                <section className="bg-zinc-800 p-2 justify-center items-center mx-auto w-11/12 mb-8">
          <div className='text-xs tracking-widest text-white flex justify-between'>
              <strong>SCARAB VASE PLINTH</strong>
              <div className='text-[0.5rem] tracking-widest text-white uppercase'>Budmen Digitization Division</div>
          </div>
            <ModelRenderer  modelUrl="https://figment-objects.s3.us-west-004.backblazeb2.com/Scarab-Vase-Base-R1.glb"/>
            <div className='text-[0.5rem] flex justify-between tracking-widest text-white uppercase'>
            <div>Points:1,013,779 | Faces: 3,518,280 </div>
              <div className="text-sm flex items-center"><span className='text-[0.5rem] mr-2'>Scan 5.17.23</span><GiVibratingBall/> </div>
          </div>
        </section>
       
      </div>
    </div>
  )
}
export default SVPage
